







































import Vue from "vue";
import Component from "vue-class-component";
import { backend } from "./modules/Global";
import logger from "./modules/Logger";
import axios from "axios";

@Component
export default class App extends Vue {
  name = "App";
  mobile = false;
  openMatches = 0;
  appointments = 0;
  unreadMessages = 0;
  showTooltip = false;

  tourOptions = {
          useKeyboardNavigation: false,
          labels: {
            buttonSkip: 'Tour überspringen',
            buttonPrevious: 'Vorher',
            buttonNext: 'Nächster',
            buttonStop: 'Tour beenden'
          }
        };

  steps = [
          {
            target: '#btnAppointments',  
            content: `Hier findest Du alle deine Termine`
          },
          {
            target: '#btnInvite',
            content: 'Hier findest Du Gäste zum Einladen'
          },
          {
            target: '#btnOpenMatches',
            content: 'Hier findest Du offene Spiele für Dich'
          },
          {
            target: '#btnChats',
            content: 'Hier kannst Du mit anderen Teams chatten'
          },
          {
            target: '#btnSettings',
            content: 'Hier kannst Du die Infos zu deinem Team ändern'
          },
        ];

  async mounted() {
    if (window.innerWidth > 700) {
      this.mobile = false;
    } else {
      this.mobile = true;
    }

    //this.refreshBadges();
    //setInterval(this.refreshBadges, 60000);

    if(localStorage.getItem("tour") === null){
      this.$tours['tour'].start();
      localStorage.setItem("tour", "1");
    }
    
    
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async backgroundTask(){
    while(true){
      await this.sleep(600000);

      if(localStorage.getItem("team") === null){
        continue;
      }

      axios
      .get(backend + "/unread_messages" , {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        for(let message of response.data){

          if(localStorage.getItem("notified_messages").includes(message.id)){
            continue;
          }
          
          const randomId = Math.floor(Math.random() * 10000) + 1;
      
          
          
          localStorage.setItem("notified_messages", (localStorage.getItem("notified_messages") + ";" + message.id));

        }
      })
      .catch((error) => {
        logger("Unread 1: " + error);
      });

      
      //Open Matches
      if(localStorage.getItem("sport") === null){
        continue;
      }
      if(localStorage.getItem("gender") === null){
        continue;
      }
      if(localStorage.getItem("age_group") === null){
        continue;
      }

      axios
      .get(backend + "/matches/open/" + localStorage.getItem("sport").toUpperCase() + "/" + 100, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        this.openMatches = 0;
        for(let match of response.data){
          try{
            if(match.organizer.ageGroup === localStorage.getItem("age_group")){
              if(match.organizer.gender === localStorage.getItem("gender")){
                
                if(localStorage.getItem("notified_messages").includes(match.id)){
                  continue;
                }
                
                const randomId = Math.floor(Math.random() * 10000) + 1;
            
                
                
                localStorage.setItem("notified_messages", (localStorage.getItem("notified_messages") + ";" + match.id));

              }
            }
          }
          catch(e){} 
        }
      })
      .catch((error) => {
        logger(error);
      });

    }
  }

  refreshBadges() {

    if(localStorage.getItem("team") === null){
      return;
    }

    if(localStorage.getItem("sport") === null){
      return;
    }

    if(localStorage.getItem("gender") === null){
      return;
    }

    if(localStorage.getItem("age_group") === null){
      return;
    }


    
    axios
      .get(backend + "/matches/open/"  + localStorage.getItem("sport").toUpperCase() + "/" + 100, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        this.openMatches = 0;
        for(let match of response.data){
          try{
            if(match.organizer.ageGroup === localStorage.getItem("age_group")){
              if(match.organizer.gender === localStorage.getItem("gender")){
                this.openMatches++;
              }
            }
          }
          catch(e){} 
        }
      })
      .catch((error) => {
        logger(error);
      });

      axios
      .get(backend + "/allappointments", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {

        this.appointments = 0;
        for(let appointment of response.data){
          if(appointment.status === "REJECTED"){
            continue;
          }
          if(appointment.type === "INTERNAL"){
            if(appointment.organizer.id !== localStorage.getItem("team")){
              continue;
            }
          }
          this.appointments++
        }
        
      })
      .catch((error) => {

        logger(error);
      });

      axios
      .get(backend + "/unread_messages", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        this.unreadMessages = response.data.length;
      })
      .catch((error) => {
        logger("Unread 1: " +  error);
      });

  }
}
