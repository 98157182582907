






























































































































import { backend, sports } from "../modules/Global";
import logger from "../modules/Logger";
import Vue from "vue";
import Component from "vue-class-component";
import axios from "axios";
import validator from "email-validator";

@Component
export default class App extends Vue {
  team = {};
  sports = [];
  ageGroups = ["Alter", "Alle", "U5", "U6", "U7", "U8", "U9", "U10", "U11", "U12", "U13", "U14", "U15", "U16", "U17", "U18", "U19", "19+", "30+", "40+", "50+", "60+"];
  grades = ["Hobby", "Verein"];
  classifications = ["Spielschwach", "Mittelstark", "Spielstark"];

  snackbar = {
    visible: false,
    text: "Ungültige Mail Adresse",
    timeout: 2000,
  };

  changeClassifications() {
    const classHobby = ["Spielschwach", "Mittelstark", "Spielstark"];
    const classVerein = ["Kreisliga", "Bezirksliga", "Landesliga", "Verbandsliga", "Oberliga", "Reginalliga", "3. Liga", "2. Liga", "Bundesliga"];

    this.classifications = classHobby;

    if (this.team.grade === "Verein") {
      switch (this.team.ageGroup) {
        case "U7":
          this.classifications = classHobby;
          break;
        case "U9":
          this.classifications = classHobby;
          break;
        case "U11":
          this.classifications = classHobby;
          break;
        case "U13":
          this.classifications = classHobby;
          break;
        case "U15":
          this.classifications = classHobby;
          break;
        case "U17":
          this.classifications = classHobby;
          break;
        default:
          this.classifications = classVerein;
      }
    }
  }

  signup() {
    if (this.isEmpty(this.team.name)) {
      this.snackbar.visible = true;
      this.snackbar.text = "Teamname fehlt!";
      return;
    }

    if (this.isEmpty(this.team.username)) {
      this.snackbar.visible = true;
      this.snackbar.text = "Mail-Adresse fehlt!";
      return;
    }

    this.team.username = this.team.username.toLowerCase();
    this.team.username = this.team.username.trim();

    const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!this.team.username.match(mailformat)) {
      this.snackbar.visible = true;
      this.snackbar.text = "Mail-Adresse ungültig!";
      return;
    }

    if (this.isEmpty(this.team.sport)) {
      this.snackbar.visible = true;
      this.snackbar.text = "Sportart fehlt!";
      return;
    }

    if (this.isEmpty(this.team.grade)) {
      this.snackbar.visible = true;
      this.snackbar.text = "Level fehlt!";
      return;
    }

    if (this.isEmpty(this.team.ageGroup)) {
      this.snackbar.visible = true;
      this.snackbar.text = "Altersgruppe fehlt!";
      return;
    }

    if (this.isEmpty(this.team.classification)) {
      this.snackbar.visible = true;
      this.snackbar.text = "Klasse fehlt!";
      return;
    }

    if (this.isEmpty(this.team.city)) {
      this.snackbar.visible = true;
      this.snackbar.text = "Stadt fehlt!";
      return;
    }

    if (this.isEmpty(this.team.firstName)) {
      this.snackbar.visible = true;
      this.snackbar.text = "Vorname fehlt!";
      return;
    }

    if (this.isEmpty(this.team.lastName)) {
      this.snackbar.visible = true;
      this.snackbar.text = "Nachname fehlt!";
      return;
    }

    axios
      .get("https://eu1.locationiq.com/v1/search.php?key=de5f7d8c8ec83c&q=" + this.team.postalCode + "," + this.team.city + "&format=json&limit=1")
      .then((response) => {
        this.team.longitude = response.data[0].lon;
        this.team.latitude = response.data[0].lat;

        this.register();
      })
      .catch(function(error) {
        this.register();
      });
  }

  register() {
    axios
      .post(backend + "/register_team", this.team)
      .then((response) => {
        this.$router.push("/signed");
      })
      .catch(function(error) {
        alert("Registrierungsanfrage fehlgeschlagen: " + error.response.data);
      });
  }

  mounted() {
    this.sports = sports;
    this.team.gender = "MALE";
  }
}
