































































































































































































































import { backend } from "../modules/Global";
import logger from "../modules/Logger";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import moment from "moment";

@Component
export default class App extends Vue {
  snackbar = {
    visible: false,
    text: "",
    timeout: 2000,
  };

  moment = moment;
  step = 1;
  guests = [];
  all_guests = [];
  counter = 0;
  location = '';
  distance = 50;
  distanceItems = ["Umkreis", "25 km", "50 km", "100 km", "150 km", "Alle"];
  ageGroups = ["Alter", "Alle", "U5", "U6", "U7", "U8", "U9", "U10", "U11", "U12", "U13", "U14", "U15", "U16", "U17", "U18", "U19", "19+", "30+", "40+", "50+", "60+"];
  distanceSelected = "Umkreis";
  ageGroupSelected = ["Alter"];
  invite = {};
  dates = [];
  times = [];
  loaded = false;

  genderSelected = "ALL";
  genderVals = [
    { val: "ALL", lab: "Geschlecht" },
    { val: "ALL", lab: "Alle" },
    { val: "MALE", lab: "Männlich" },
    { val: "FEMALE", lab: "Weiblich" },
    { val: "MIXED", lab: "Gemischt" },
  ];

  changeSelect(){

    if(this.ageGroupSelected.length === 0){
      this.ageGroupSelected = ["Alter"];
    }

    if(this.ageGroupSelected.length > 1){
      let i = 0;
      for(let ageGroup of this.ageGroupSelected){
        if(ageGroup === "Alter"){
          this.ageGroupSelected.splice(i,1);
        }
        i++;
      }
    }

    this.counter = 0;
    this.guests = [];
    this.addGuestsToView();

  }

  refresh() {

    this.loaded = false;
    this.counter = 0;
    this.guests = [];

    if (this.$route.params.opt === "open") {
      document.getElementById("header").innerHTML = "Offenes Spiel"
      this.invite.guest = "open";
      this.invite.label = localStorage.getItem("teamname");
      this.step = 2;
    }
    this.invite.sport = localStorage.getItem("sport").toUpperCase();
    this.invite.location = "Heim";
    this.invite.tournamentSwitch = "Spiel";

    let distance = 30;
    if (this.distanceSelected === "25 km") {
      distance = 25;
    }
    if (this.distanceSelected === "50 km") {
      distance = 50;
    }
    if (this.distanceSelected === "100 km") {
      distance = 100;
    }
    if (this.distanceSelected === "150 km") {
      distance = 150;
    }
    if (this.distanceSelected === "Alle") {
      distance = 9999;
    }
    if (this.distanceSelected === "Umkreis") {
      distance = 100;
    }

    if (this.isEmpty(this.location)) {

      axios
      .get(backend + "/guests/" + localStorage.getItem("sport").toUpperCase() + "/" + distance, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        this.all_guests = response.data; 
        this.addGuestsToView();

        this.loaded = true;
      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });

    }
    else{

      axios
      .get("https://eu1.locationiq.com/v1/search.php?key=de5f7d8c8ec83c&q=" + this.location + "&format=json&limit=1")
      .then((response) => {

         axios
        .get(backend + "/guests/" +  localStorage.getItem("sport").toUpperCase() + "/" + distance  + "/" + response.data[0].lat + "/" + response.data[0].lon, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          this.all_guests = response.data; 
          this.addGuestsToView();
          this.loaded = true;
        })
        .catch((error) => {
          this.snackbar.visible = true;
          this.snackbar.text = error.message;

          logger(error);
        });

        
      })
      .catch(function(error) {
          this.snackbar.visible = true;
          this.snackbar.text = error.message;
          this.loaded = true;
          logger(error);
      });

    }

    
  }

  addGuestsToView(){

    if(this.counter < this.all_guests.length ){

      let i = 0;

      while(this.counter < this.all_guests.length){

        let guest = this.all_guests[this.counter];
        let addGuest = true;
       
        if(this.ageGroupSelected.includes('Alter') || this.ageGroupSelected.includes('Alle')){
          //
        }
        else{

          if(this.ageGroupSelected.includes(guest.age_group)){
            //
          }
          else{
            addGuest = false;
          }
        }

        

        if(this.genderSelected === 'Geschlecht' || this.genderSelected === 'ALL'){
          //
        }
        else{

          if(this.genderSelected === guest.gender){
            //
          }
          else{
            addGuest = false;
          }
        }

        
        if(addGuest){
          this.guests.push(guest);
          i++;
        }

        this.counter++;

        if(i >= 5){
          return;
        }
        
      }
    }
    
  }

  onScroll(e) {
    
    if ((window.innerHeight + Math.round(window.scrollY)) >= document.body.offsetHeight) {
      this.addGuestsToView();
    }
  }

  ranking(gid) {
    this.$router.push("/rating/" + gid);
  }

  chooseGuest(gid) {
    this.invite.guest = gid;
    this.step = 2;
  }

  checkStep2() {
    if (!this.isEmpty(this.invite.label)) {
      this.step = 3;
    }
    if (!this.isEmpty(this.invite.location)) {
      this.step = 3;
    }
    if (!this.isEmpty(this.invite.note)) {
      this.step = 3;
    }
  }

  createInvitation() {
    if (this.invite.tournamentSwitch === "Turnier") {
      this.invite.tournament = "1";
    } else {
      this.invite.tournament = "0";
    }

    let invitations = [];
    for (const dat of this.dates) {
      let invitation = {};
      invitation.tournament = this.invite.tournament;
      invitation.guest = this.invite.guest;
      invitation.label = this.invite.label;
      invitation.location = this.invite.location;
      invitation.note = this.invite.note;
      invitation.sport = this.invite.sport;
      invitation.start_time = moment(dat + "T" + this.times[dat] + ":00").format("YYYY-MM-DDTHH:mm:ss");

      if (this.times[dat] === undefined) {
        this.snackbar.text = "Uhrzeit für Datum " + dat + " nicht korrekt (HH:MM)";
        this.snackbar.visible = true;
        return;
      }

      if (this.times[dat].length !== 5) {
        this.snackbar.text = "Uhrzeit für Datum " + dat + " nicht korrekt (HH:MM)";
        this.snackbar.visible = true;
        return;
      }

      if (this.times[dat].substring(2, 3) !== ":") {
        this.snackbar.text = "Uhrzeit für Datum " + dat + " nicht korrekt (HH:MM)";
        this.snackbar.visible = true;
        return;
      }

      try {
        const hh = parseInt(this.times[dat].substring(0, 2));
        const mm = parseInt(this.times[dat].substring(3, 5));

        if (hh < 0 || hh > 23 || mm < 0 || mm > 59) {
          this.snackbar.text = "Uhrzeit für Datum " + dat + " nicht korrekt (HH:MM)";
          this.snackbar.visible = true;
          return;
        }
      } catch (e) {
        this.snackbar.text = "Uhrzeit für Datum " + dat + " nicht korrekt (HH:MM)";
        this.snackbar.visible = true;
        return;
      }

      invitations.push(invitation);
    }

    for (const invitation of invitations) {
      axios
        .post(backend + "/match/" + this.invite.guest, invitation, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          console.log("successfully");
        })
        .catch((error) => {
          this.snackbar.visible = true;
          this.snackbar.text = error.message;

          logger(error);
        });
    }

    this.invite.guest = "";
    this.invite.label = "";
    this.invite.location = "Heim";
    this.invite.start_time = "";
    this.invite.tournamentSwitch = "Spiel";
    this.invite.note = "";

    this.step = 1;
  }

  back() {
    if (this.step > 1) {
      this.step--;
    }
  }

  mounted() {
    this.refresh();
    window.addEventListener('scroll', this.onScroll);
  }
}
