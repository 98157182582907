





























import { backend } from "../modules/Global";
import logger from "../modules/Logger";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import moment from "moment";

@Component
export default class App extends Vue {
  snackbar = {
    visible: false,
    text: "",
    timeout: 2000,
  };

  games = {};

  save(){
    console.info(this.games)
  }

  refresh() {

    axios
      .get(backend + "/games/" + this.$route.params.id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        this.games = response.data;
      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });
  }

  mounted() {
    this.refresh();
  }
}
