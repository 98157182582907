





















































































































































































































import { backend } from "../modules/Global";
import logger from "../modules/Logger";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import moment from "moment";

@Component
export default class App extends Vue {
  snackbar = {
    visible: false,
    text: "",
    timeout: 2000,
  };
  moment = moment;
  date = new Date().toISOString().substr(0, 10);
  calendarEvents = [];
  appointments = [];
  showCreate = 0;
  internalTime = '';
  internalMatch = {};

  get internalDateTime() {
    
    return moment(this.date).format("DD.MM.YYYY") + ' ' + this.internalTime;
  }

  refresh() {

    this.appointments = [];
    this.calendarEvents = [];

    axios
      .get(backend + "/appointments", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        let results = response.data;

        for (let appointment of results) {
          if (appointment.guest === null && appointment.organizer_id !== localStorage.getItem("team")) {
            continue;
          }
          if (appointment.status === "REJECTED") {
            continue;
          }

          let event = moment(appointment.start).format("YYYY-MM-DD");
          this.calendarEvents.push(event);
          this.appointments.push(appointment);
        }
      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });
  }

  mounted() {
    this.refresh();
  }

  createInternalMatch(){

    if(this.internalMatch.label === undefined){
      this.$swal('Termintitel fehlt');
      return;
    }

    if(this.internalDateTime.length !== 16){
      this.$swal('Uhrzeit fehlt');
      return;
    }

    let tmp = moment(this.internalDateTime, "DD.MM.YYYY kk:mm", true);

    if(tmp.isValid() === false){
      this.$swal('Ungültiges Datum oder Uhrzeit.');
      return;
    }
    
    this.internalMatch.startTime = tmp.format("YYYY-MM-DDTHH:mm:ss");

    axios
      .post(backend + "/match" , this.internalMatch, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        this.showCreate = 0;
        this.refresh();
      })
      .catch((error) => {
        this.$swal(error.message);

        logger(error);
      });

  }

  async remove(id) {

    try{
      const r = await this.$swal({ text: "Bist Du sicher????", confirmButtonText: "Ja", showDenyButton: true, denyButtonText: "Nein" });
      if (r.value) {

        const respone = await axios.delete(backend + "/match/" + id, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth"),
            },
          });

          this.refresh();
      }
    }
    catch(e){
      this.$swal(e.message);
    }
  }

  confirm(id) {
    this.$swal({ text: "Bist Du sicher?", confirmButtonText: "Ja", showDenyButton: true, denyButtonText: "Nein" }).then((r) => {
      if (r.value) {
        axios
          .get(backend + "/invitation/confirm/"  + id, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth"),
            },
          })
          .then((response) => {
            this.refresh();
          })
          .catch((error) => {
            this.snackbar.visible = true;
            this.snackbar.text = error.message;

            logger(error);
          });
      }
    });
  }

  confirmOpenMatch(id) {
    this.$swal({ text: "Bist Du sicher?", confirmButtonText: "Ja", showDenyButton: true, denyButtonText: "Nein" }).then((r) => {
      if (r.value) {
        axios
          .get(backend + "/match/open/invite/" + id, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth"),
            },
          })
          .then((response) => {
            this.refresh();
          })
          .catch((error) => {
            this.snackbar.visible = true;
            this.snackbar.text = error.message;

            logger(error);
          });
      }
    });
  }

  reject(id) {
    this.$swal({ text: "Bist Du sicher?", confirmButtonText: "Ja", showDenyButton: true, denyButtonText: "Nein" }).then((r) => {
      if (r.value) {
        let reason = "Kein Grund angegeben";

        axios
          .put(backend + "/invitation/reject/" + id, reason, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth"),
              "Content-Type": "text/plain",
            },
          })
          .then((response) => {
            this.refresh();
          })
          .catch((error) => {
            this.snackbar.visible = true;
            this.snackbar.text = error.message;

            logger(error);
          });
      }
    });
  }
}
