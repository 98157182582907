


















































































import { backend } from "../modules/Global";
import logger from "../modules/Logger";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import moment from "moment";

@Component
export default class App extends Vue {
  snackbar = {
    visible: false,
    text: "",
    timeout: 2000,
  };

  moment = moment;
  players = [2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];
  playgrounds = [1,2,3,4,5,6,7,8,9,10];
  modus = ["1 Gruppe - Jeder gegen jeden", 
  "2 Gruppen + Halbfinale + Spiel um Platz 3 + Finale", 
  "2 Gruppen + Halbfinale + Finale", 
  "2 Gruppen + Finale", 
  "4 Gruppen + Viertelfinale + Halbfinale + Finale",
  "4 Gruppen + Viertelfinale + Halbfinale + Spiel um Platz 3 + Finale"];
  tournament = {
    "label": "Turnier",
    "player": 4,
    "duration": 10,
    "break_duration": 10,
    "playgrounds": 1,
    "modus": "2 Gruppen + Finale",
    "start_time": "2006-01-02T15:04:05"
  };

  save() {
    axios
      .post(backend + "/tournament", this.tournament, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        this.$router.push("tournament_guests/" + response.data);
      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });
  }

  mounted() {
    

  }
}
