






























import { backend } from "../modules/Global";
import logger from "../modules/Logger";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class App extends Vue {
  members = [];

  snackbar = {
    visible: false,
    text: "",
    timeout: 2000,
  };

  role(val) {
    if(val === 0) return "Leiter"
    return "Gast"
  }

  edit(mid) {
    this.$router.push("/member/" + mid);
  }

  mounted() {
    axios
      .get(backend + "/members", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        this.members = response.data;
      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });
  }
}
