










































































































































































































import { backend } from "../modules/Global";
import logger from "../modules/Logger";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import moment from "moment";

@Component
export default class App extends Vue {
  openAppointments = [];
  moment = moment;
  snackvis = false;

  snackbar = {
    visible: false,
    text: "Ungültige Mail Adresse",
    timeout: 2000,
  };

  async remove(id) {

    try{
      const r = await this.$swal({ text: "Bist Du sicher???", confirmButtonText: "Ja", showDenyButton: true, denyButtonText: "Nein" });
      if (r.value) {
        const response = await axios.delete(backend + "/match/"  + id, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth"),
            },
          });
          this.refresh();
      }

    }
    catch(e){
      this.$swal(e.message);
    }

  }

  async confirm(id) {

    try{
      const r = await this.$swal({ text: "Bist Du sicher?", confirmButtonText: "Ja", showDenyButton: true, denyButtonText: "Nein" })
      if (r.value) {
        const response = await axios.get(backend + "/invitation/confirm/"  + id, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth"),
            },
          });

          this.refresh();
      }
    }
    catch(e){
      this.$swal(e.message);
    }

  }

  async confirmOpenMatch(id) {

    try{
      const r = await this.$swal({ text: "Bist Du sicher?", confirmButtonText: "Ja", showDenyButton: true, denyButtonText: "Nein" });
      if (r.value) {
        const response = await axios.get(backend + "/match/open/invite/" + id, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth"),
            },
          });
        this.refresh();
      }
    }
    catch(e){
      this.$swal(e.message);
    }

    this.$swal({ text: "Bist Du sicher?", confirmButtonText: "Ja", showDenyButton: true, denyButtonText: "Nein" }).then((r) => {
      if (r.value) {
        axios.get(backend + "/match/open/invite/" + id, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth"),
            },
          })
          .then((response) => {
            this.refresh();
          })
          .catch((error) => {
            this.snackbar.visible = true;
            this.snackbar.text = error.message;

            logger(error);
          });
      }
    });
  }

  async reject(id) {
    this.$swal({ text: "Bist Du sicher?", confirmButtonText: "Ja", showDenyButton: true, denyButtonText: "Nein" }).then((r) => {
      if (r.value) {
        let reason = "Kein Grund angegeben";

        axios
          .put(backend + "/invitation/reject/" + id, reason, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth"),
              "Content-Type": "text/plain",
            },
          })
          .then((response) => {
            this.refresh();
          })
          .catch((error) => {
            this.snackbar.visible = true;
            this.snackbar.text = error.message;

            logger(error);
          });
      }
    });
  }

  async refresh() {
    axios
      .get(backend + "/appointments", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        this.openAppointments = response.data;
      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });
  }

  async mounted() {
    this.refresh();

    axios
      .get(backend + "/team", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        let team = response.data;
        if (team.grade === null) {
          this.snackvis = true;
        }
        if (team.ageGroup === null) {
          this.snackvis = true;
        }
        if (team.classification === null) {
          this.snackvis = true;
        }
      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });
  }
}
