










































import {backend} from '../modules/Global';
import logger from '../modules/Logger';
import axios from "axios";
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class App extends Vue {

    user = {}:

    snackbar= {
      visible: false,
      text: "",
      timeout: 2000
    };

    save(){

      axios
      .put(backend + "/user",
      this.user,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth")
        }
      })
      .then(response => {
        this.$router.push("settings");
      })
      .catch(error => {
            this.snackbar.visible = true;
            this.snackbar.text = error.message;

            logger(error);
          });

    }


  mounted() {

    axios
      .get(backend + "/user", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth")
        }
      })
      .then(response => {
        this.user = response.data;

      })
      .catch(error => {
            this.snackbar.visible = true;
            this.snackbar.text = error.message;

            logger(error);
          });
  }
};
