



















































































import { backend } from "../modules/Global";
import logger from "../modules/Logger";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class App extends Vue {
  logout() {
    localStorage.removeItem("team");
    localStorage.removeItem("auth");
    localStorage.removeItem("sport");
    localStorage.removeItem("role");
    this.$router.push("/login");
  }

  tour() {
    this.$tours['tour'].start();
  }

  mounted() {
    window.scrollTo(0, 0);
  }
}
