




























































import { backend } from "../modules/Global";
import logger from "../modules/Logger";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class App extends Vue {
  snackbar = {
    visible: false,
    text: "",
    timeout: 2000,
  };

  member = {};
  
  roles = [
  { val: 0, lab: "Leiter" },
  { val: 1, lab: "Mitglied" },
  { val: 2, lab: "Gast" },];

  snackbar = {
    visible: false,
    text: "Ungültige Mail Adresse",
    timeout: 2000,
  };

  save() {
    if (this.isEmpty(this.member.username)) {
      this.snackbar.visible = true;
      this.snackbar.text = "E-Mail fehlt!";
      return;
    }

    const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!this.member.username.match(mailformat)) {
      this.snackbar.visible = true;
      this.snackbar.text = "Mail-Adresse ungültig!";
      return;
    }

    if (this.isEmpty(this.member.first_name)) {
      this.snackbar.visible = true;
      this.snackbar.text = "Vorname fehlt!";
      return;
    }

    if (this.isEmpty(this.member.last_name)) {
      this.snackbar.visible = true;
      this.snackbar.text = "Nachname fehlt!";
      return;
    }

    if (this.isEmpty(this.member.role)) {
      this.snackbar.visible = true;
      this.snackbar.text = "Rolle fehlt!";
      return;
    }

    if (this.isEmpty(this.member.id)) {
      axios
        .post(backend + "/member", this.member, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          this.$router.push("/member");
        })
        .catch((error) => {
          this.snackbar.visible = true;
          this.snackbar.text = error.message;

          logger(error);
        });
    } else {
      axios
        .put(backend + "/member/" + this.member.id, this.member, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          this.$router.push("/member");
        })
        .catch((error) => {
          this.snackbar.visible = true;
          this.snackbar.text = error.message;

          logger(error);
        });
    }
  }

  remove() {
    if (!this.isEmpty(this.member.id)) {
      axios
        .get(backend + "/member/remove/" + this.member.id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          this.$router.push("/member");
        })
        .catch((error) => {
          this.snackbar.visible = true;
          this.snackbar.text = error.message;

          logger(error);
        });
    }
  }

  mounted() {
    if (this.$route.params.id !== "0") {
      axios
        .get(backend + "/member/" + this.$route.params.id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          this.member = response.data;
          this.user = this.member.user;
        });
    }
  }
}
