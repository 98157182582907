
































import { backend } from "../modules/Global";
import logger from "../modules/Logger";
import axios from "axios";
import moment from "moment";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class App extends Vue {
  moment = moment;
  messages = [];
  messagetext = "";

  snackbar = {
    visible: false,
    text: "",
    timeout: 2000,
  };

  own(message){
    if(message.team === null){
      return false;
    }
    if((message.team_id === localStorage.getItem('team'))){
      return true;
    }
    return false;
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async refresh() {

    try{
      const response = await axios
      .get(backend + "/messages/" + this.$route.params.id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      });

      this.messages = response.data;
      await this.sleep(100);
      window.scrollTo(0, 10000000);
    }
    catch(e){
      this.$swal(e.message);
    }
    
  }

  async send() {

    try{
      if (this.isEmpty(this.messagetext)) {
        return;
      }

      let messageToSend = {
        team: localStorage.getItem("team"),
        chat: this.$route.params.id,
        message: this.messagetext,
      };

      const response = await axios.post(backend + "/message", messageToSend, 
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      });

      this.messagetext = "";
      await this.refresh();

    }
    catch(e){
      this.$swal(e.message);
    }
  }

  async mounted() {
    await this.refresh();
  }
}
