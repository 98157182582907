













































































































































import { backend } from "../modules/Global";
import logger from "../modules/Logger";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import moment from "moment";

@Component
export default class App extends Vue {
  snackbar = {
    visible: false,
    text: "",
    timeout: 2000,
  };

  moment = moment;

  guests = [];
  tournament = {};
  location = '';
  distance = 50;
  distanceItems = ["Umkreis", "25 km", "50 km", "100 km", "150 km", "Alle"];
  ageGroups = ["Alter", "Alle", "U5", "U6", "U7", "U8", "U9", "U10", "U11", "U12", "U13", "U14", "U15", "U16", "U17", "U18", "U19", "19+", "30+", "40+", "50+", "60+"];
  distanceSelected = "Umkreis";
  ageGroupSelected = ["Alter"];
  invite = {};
  dates = [];
  choosen = [];
  loaded = false;

  genderSelected = "ALL";
  genderVals = [
    { val: "ALL", lab: "Geschlecht" },
    { val: "ALL", lab: "Alle" },
    { val: "MALE", lab: "Männlich" },
    { val: "FEMALE", lab: "Weiblich" },
    { val: "MIXED", lab: "Gemischt" },
  ];

  choose(gid) {
    this.choosen.push(gid);
    console.info(this.choosen);
  }

  unchoose(gid) {
    this.choosen.splice(this.choosen.indexOf(gid),1);
    console.info(this.choosen);
  }

  async save() {

    try{

      for(let teamid of this.choosen){

        let comp = {
          "tournament_id": this.$route.params.id,
          "team_id": teamid
        }

        await axios.post(backend + "/competitor", comp, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        });

      }

      this.$router.push("/tournament_games/" + this.$route.params.id);
    }
    catch(error){
      this.snackbar.visible = true;
      this.snackbar.text = error.message;
      logger(error);
    }
  }

  changeSelect(){

    if(this.ageGroupSelected.length === 0){
      this.ageGroupSelected = ["Alter"];
    }

    if(this.ageGroupSelected.length > 1){
      let i = 0;
      for(let ageGroup of this.ageGroupSelected){
        if(ageGroup === "Alter"){
          this.ageGroupSelected.splice(i,1);
        }
        i++;
      }
    }

  }

  refresh() {

    this.loaded = false;

    axios
      .get(backend + "/tournament/" + this.$route.params.id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        this.tournament = response.data;
      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });

    let distance = 30;
    if (this.distanceSelected === "50 km") {
      distance = 50;
    }
    if (this.distanceSelected === "100 km") {
      distance = 100;
    }
    if (this.distanceSelected === "Alle") {
      distance = 9999;
    }
    if (this.distanceSelected === "Umkreis") {
      distance = 100;
    }

    if (this.isEmpty(this.location)) {

      axios
      .get(backend + "/guests/" + localStorage.getItem("sport").toUpperCase() + "/" + distance, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        this.guests = response.data;
        this.loaded = true;
      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });

    }
    else{

      axios
      .get("https://eu1.locationiq.com/v1/search.php?key=de5f7d8c8ec83c&q=" + this.location + "&format=json&limit=1")
      .then((response) => {

         axios
        .get(backend + "/guests/" +  localStorage.getItem("sport").toUpperCase() + "/" + distance  + "/" + response.data[0].lat + "/" + response.data[0].lon, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          this.guests = response.data;
          this.loaded = true;
        })
        .catch((error) => {
          this.snackbar.visible = true;
          this.snackbar.text = error.message;

          logger(error);
        });

        
      })
      .catch(function(error) {
          this.snackbar.visible = true;
          this.snackbar.text = error.message;
          this.loaded = true;
          logger(error);
      });

    }
  }

  mounted() {
    this.refresh();
  }
}
