









import logger from "../modules/Logger";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class App extends Vue {
  logout() {
    localStorage.removeItem("team");

    localStorage.removeItem("auth");

    localStorage.removeItem("sport");
    localStorage.removeItem("role");
    localStorage.removeItem("gender");
    localStorage.removeItem("age_group");

    this.$router.push("/login");
  }
}
