













































































































import { backend } from "../modules/Global";
import logger from "../modules/Logger";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import { Camera, CameraResultType } from '@capacitor/camera';

@Component
export default class App extends Vue {
  team = {};
  ageGroups = ["Alter", "Alle", "U5", "U6", "U7", "U8", "U9", "U10", "U11", "U12", "U13", "U14", "U15", "U16", "U17", "U18", "U19", "19+", "30+", "40+", "50+", "60+"];
  grades = ["Hobby", "Verein"];
  classifications = ["Spielschwach", "Mittelstark", "Spielstark"];
  photoChange = 0;
  reallyRemove = 0;

  snackbar = {
    visible: false,
    text: "",
    timeout: 2000,
  };

  changeClassifications() {
    const classHobby = ["Spielschwach", "Mittelstark", "Spielstark"];
    const classVerein = ["Kreisliga", "Bezirksliga", "Landesliga", "Verbandsliga", "Oberliga", "Reginalliga", "3. Liga", "2. Liga", "Bundesliga"];

    this.classifications = classHobby;

    if (this.team.grade === "Verein") {
      switch (this.team.ageGroup) {
        case "U7":
          this.classifications = classHobby;
          break;
        case "U9":
          this.classifications = classHobby;
          break;
        case "U11":
          this.classifications = classHobby;
          break;
        case "U13":
          this.classifications = classHobby;
          break;
        case "U15":
          this.classifications = classHobby;
          break;
        case "U17":
          this.classifications = classHobby;
          break;
        default:
          this.classifications = classVerein;
      }
    }
  }

  save() {
    axios
      .put(backend + "/team", this.team, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {

        if(this.photoChange === 1){

          var base64 = document.getElementById('previewing_photo').getAttribute('src');
          if(base64 === null || base64.length === 0){
              this.$router.push("settings");
          }
          else{
            axios.put(backend + "/team/photo", base64, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("auth"),
                'Content-Type': 'plain/text',
              },
            })
            .then((response) => {
              this.$router.push("settings");
            })
            .catch((error) => {
              this.snackbar.visible = true;
              this.snackbar.text = error.message;
              
              logger(error);
            });
          }

        }
        else{
          this.$router.push("settings");
        }

      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });
  }

  async openPhotoCamera() {

    try{
      const picture = await Camera.getPicture({
        resultType: CameraResultType.Uri
      });
      document.getElementById('previewing_photo').setAttribute('src', "data:image/jpeg;base64," + picture);
      this.photoChange = 1;
    }
    catch(err){
        document.getElementById('photofile').click();
    }

  }

  photofileChanged(){
    var file = document.getElementById('photofile').files[0];
    var imagefile = file.type;
    var match = ["image/jpeg", "image/png", "image/jpg"];
    if (!((imagefile == match[0]) || (imagefile == match[1]) || (imagefile == match[2]))) {
        return false;
    }
    else {
        var reader = new FileReader();
        reader.onload = function(e) {
          document.getElementById('previewing_photo').setAttribute('src', e.target.result);
        };
        reader.readAsDataURL(document.getElementById('photofile').files[0]);
        this.photoChange = 1;
    }
  }

  remove() {

    axios
      .delete(backend + "/team", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        this.$router.push("settings");
      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });
  }

  mounted() {
    axios.get(backend + "/team", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        this.team = response.data;
        
        if(this.team.photo !== null){
          document.getElementById('previewing_photo').setAttribute('src', this.team.photo);
        }

      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });
  }
}
