import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import App from "./App.vue";
import router from "./modules/Routes.js";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueTour from 'vue-tour';
import "vue-tour/dist/vue-tour.css";
import VueGtag from "vue-gtag";

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    isEmpty: function(val) {
      if (val === undefined) {
        return true;
      }
      if (val === null) {
        return true;
      }
      if (val.length === 0) {
        return true;
      }
      return false;
    },
    loggedIn: function() {
      if (!this.isEmpty(localStorage.getItem("auth")) && !this.isEmpty(localStorage.getItem("team"))) {
        return true;
      } else {
        return false;
      }
    },
  },
});

Vue.use(VueGtag, {config: {id: "G-L1B472CPSY"} }, router);

Vue.use(VueTour);
Vue.use(Vuetify);
Vue.use(VueSweetalert2);
const vuetify = new Vuetify({
  theme: {
    dark: false,
  },
});

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
