
















































































































































import { backend } from "../modules/Global";
import logger from "../modules/Logger";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import moment from "moment";

@Component
export default class App extends Vue {
  moment = moment;
  matches = [];
  teamid = localStorage.getItem("team");
  loaded = false;

  location = '';
  distance = 100;
  distanceItems = ["Umkreis", "25 km", "50 km", "100 km", "150 km", "Alle"];
  distanceSelected = "Umkreis";

  genderSelected = "ALL";
  genderVals = [
    { val: "ALL", lab: "Geschlecht" },
    { val: "ALL", lab: "Alle" },
    { val: "MALE", lab: "Männlich" },
    { val: "FEMALE", lab: "Weiblich" },
    { val: "MIXED", lab: "Gemischt" },
  ];

  ageGroups = ["Alter", "Alle", "U5", "U6", "U7", "U8", "U9", "U10", "U11", "U12", "U13", "U14", "U15", "U16", "U17", "U18", "U19", "19+", "30+", "40+", "50+", "60+"];
  ageGroupSelected = ["Alter"];

  snackbar = {
    visible: false,
    text: "",
    timeout: 2000,
  };

  edit(mid) {
    this.$router.push("/match/" + mid);
  }

  translateLevel(level) {
    switch (level) {
      case "BEGINNER":
        return "Beginner";
      case "ADVANCED":
        return "Fortgeschritten";
      case "PROFESSIONAL":
        return "Profi";
      case "CLUB":
        return "Verein";
      default:
        return "";
    }
  }

  changeSelect(){

    if(this.ageGroupSelected.length === 0){
      this.ageGroupSelected = ["Alter"];
    }

    if(this.ageGroupSelected.length > 1){
      let i = 0;
      for(let ageGroup of this.ageGroupSelected){
        if(ageGroup === "Alter"){
          this.ageGroupSelected.splice(i,1);
        }
        i++;
      }
    }

  }

  refresh() {
    this.loaded = false;

    let distance = 30;
    if (this.distanceSelected === "25 km") {
      distance = 25;
    }
    if (this.distanceSelected === "50 km") {
      distance = 50;
    }
    if (this.distanceSelected === "100 km") {
      distance = 100;
    }
    if (this.distanceSelected === "150 km") {
      distance = 150;
    }
    if (this.distanceSelected === "Alle") {
      distance = 9999;
    }
    if (this.distanceSelected === "Umkreis") {
      distance = 100;
    }

    if (this.isEmpty(this.location)) {
      axios
        .get(backend + "/matches/open/" + localStorage.getItem("sport").toUpperCase() + "/" + distance, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          this.matches = response.data;
          this.loaded = true;
        })
        .catch((error) => {
          this.snackbar.visible = true;
          this.snackbar.text = error.message;
          this.loaded = true;

          logger(error);
        });
    }
    else{
      
      axios
      .get("https://eu1.locationiq.com/v1/search.php?key=de5f7d8c8ec83c&q=" + this.location + "&format=json&limit=1")
      .then((response) => {

         axios
        .get(backend + "/matches/open/" + localStorage.getItem("sport").toUpperCase() + "/" + distance + "/" + response.data[0].lat + "/" + response.data[0].lon, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .then((response) => {
          this.matches = response.data;
          this.loaded = true;
        })
        .catch((error) => {
          this.snackbar.visible = true;
          this.snackbar.text = error.message;
          this.loaded = true;

          logger(error);
        });

        
      })
      .catch(function(error) {
          this.snackbar.visible = true;
          this.snackbar.text = error.message;
          this.loaded = true;
          logger(error);
      });

    }
  }

  confirmOpenMatch(id) {
    this.$swal({ text: "Bist Du sicher?", confirmButtonText: "Ja", showDenyButton: true, denyButtonText: "Nein" }).then((r) => {
      if (r.value) {
        axios
          .get(backend + "/match/open/invite/" + id, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth"),
            },
          })
          .then((response) => {
            this.refresh();
          })
          .catch((error) => {
            this.snackbar.visible = true;
            this.snackbar.text = error.message;

            logger(error);
          });
      }
    });
  }

  startChat(id) {
    let chat = {
      label: "",
      team: localStorage.getItem("team"),
      guest: id,
    };

    axios
      .post(backend + "/chat", chat, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        this.$router.push("chat/" + response.data);
      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });
  }

  mounted() {
    this.refresh();
  }
}
