


















































































import { backend } from "../modules/Global";
import logger from "../modules/Logger";
import axios from "axios";
import moment from "moment";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class App extends Vue {
  guests = [];
  distanceItems = ["Umkreis", "25 km", "50 km", "100 km", "150 km", "Alle"];
  ageGroups = ["Alter", "Alle", "U5", "U6", "U7", "U8", "U9", "U10", "U11", "U12", "U13", "U14", "U15", "U16", "U17", "U18", "U19", "19+", "30+", "40+", "50+", "60+"];
  distanceSelected = "Umkreis";
  ageGroupSelected = ["Alter"];
  loaded = false;

  genderSelected = "ALL";
  genderVals = [
     { val: "ALL", lab: "Geschlecht" },
    { val: "ALL", lab: "Alle" },
    { val: "MALE", lab: "Männlich" },
    { val: "FEMALE", lab: "Weiblich" },
    { val: "MIXED", lab: "Gemischt" },
  ];

  snackbar = {
    visible: false,
    text: "",
    timeout: 2000,
  };

  changeSelect(){

    if(this.ageGroupSelected.length === 0){
      this.ageGroupSelected = ["Alter"];
    }

    if(this.ageGroupSelected.length > 1){
      let i = 0;
      for(let ageGroup of this.ageGroupSelected){
        if(ageGroup === "Alter"){
          this.ageGroupSelected.splice(i,1);
        }
        i++;
      }
    }

  }

  refresh() {
    this.loaded = false;  

    let distance = 30;
    if (this.distanceSelected === "50 km") {
      distance = 50;
    }
    if (this.distanceSelected === "100 km") {
      distance = 100;
    }
    if (this.distanceSelected === "Alle") {
      distance = 9999;
    }
    if (this.distanceSelected === "Umkreis") {
      distance = 50;
    }

    axios
      .get(backend + "/guests/" + localStorage.getItem("sport").toUpperCase() + "/" + distance, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        this.guests = response.data;
        this.loaded = true;
      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });
  }

  startChat(id) {
    let chat = {
      label: "",
      team: localStorage.getItem("team"),
      guest: id,
    };

    axios
      .post(backend + "/chat", chat, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        this.$router.push("chat/" + response.data);
      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });
  }

  mounted() {
    this.refresh();
  }
}
