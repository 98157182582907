







































import { backend } from "../modules/Global";
import logger from "../modules/Logger";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class App extends Vue {
  teams = [];

  snackbar = {
    visible: false,
    text: "",
    timeout: 2000,
  };

  setTeam(tid, sport, label, level, gender, ageGroup) {
    localStorage.setItem("team", tid);
    localStorage.setItem("sport", sport);
    localStorage.setItem("level", level);
    localStorage.setItem("teamname", label);
    localStorage.setItem("gender", gender);
    localStorage.setItem("age_group", ageGroup);
    localStorage.setItem("role", "GUEST");

    axios
      .get(backend + "/roles/" + tid, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {

        if (response.data.leader) {
          localStorage.setItem("role", "LEADER");
        } else {
          if (response.data.member) {
            localStorage.setItem("role", "MEMBER");
          }
        }

        axios
          .get(backend + "/setteam/" + tid, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth"),
            },
          })
          .then((response) => {
            
            localStorage.setItem("auth", response.data);

            this.$router.push("/");
          })
          .catch((error) => {
            this.snackbar.visible = true;
            this.snackbar.text = error.message;

            logger(error);
          });
      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });
  }

  mounted() {
    axios
      .get(backend + "/teams", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        this.teams = response.data;

        if (this.teams.length === 1) {
          this.setTeam(this.teams[0].id, this.teams[0].sport, this.teams[0].label, this.teams[0].level);
        }
      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });
  }
}
