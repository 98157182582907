



















































import { backend } from "../modules/Global";
import logger from "../modules/Logger";
import axios from "axios";
import moment from "moment";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class App extends Vue {
  moment = moment;
  chats = [];
  loaded = false;

  snackbar = {
    visible: false,
    text: "",
    timeout: 2000,
  };

  open(id) {
    this.$router.push("/chat/" + id);
  }

  getTitle(obj) {
    let title = "";

    if (this.isEmpty(obj.subscribers[0])) {
      return "";
    }
    if (this.isEmpty(obj.subscribers[1])) {
      return "";
    }

    if (obj.subscribers[0].team.id === localStorage.getItem("team")) {
      title = obj.subscribers[1].team.label;
    } else {
      title = obj.subscribers[0].team.label;
    }

    return title;
  }

  refresh() {
    this.loaded = false;

    axios
      .get(backend + "/chats", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        this.chats = response.data;
        this.loaded = true;
      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });
  }

  mounted() {
    this.refresh();
  }
}
