const backend = "https://goapi.freundschaftsspiele.app";
//const backend = "http://localhost:3000";

const sports = [
  { val: "AMERICAN_FOOTBALL", lab: "American Football" },
  { val: "BADMINTON", lab: "Badminton" },
  { val: "BASEBALL", lab: "Baseball" },
  { val: "BASKETBALL", lab: "Basketball" },
  { val: "BEACHVOLLEYBALL", lab: "Beach Volleyball" },
  { val: "BILLIARD", lab: "Billiard" },
  { val: "BOCCIA", lab: "Boccia" },
  { val: "BOSSELN", lab: "Bosseln" },
  { val: "BOWLING", lab: "Bowling" },
  { val: "CURLING", lab: "Curling" },
  { val: "DART", lab: "Dart" },
  { val: "ICEHOCKEY", lab: "Eishockey" },
  { val: "FIELDHOCKEY", lab: "Feldhockey" },
  { val: "FRISBEE", lab: "Frisbee" },
  { val: "SOCCER", lab: "Fussball" },
  { val: "FUTSAL", lab: "Futsal" },
  { val: "GOLF", lab: "Golf" },
  { val: "HANDBALL", lab: "Handball" },
  { val: "KEGELN", lab: "Kegeln" },
  { val: "MINIGOLF", lab: "Minigolf" },
  { val: "PAINTBALL", lab: "Paintball" },
  { val: "RUGBY", lab: "Rugby" },
  { val: "SQUASH", lab: "Squash" },
  { val: "TENNIS", lab: "Tennis" },
  { val: "TABLETENNIS", lab: "Tischtennis" },
  { val: "VOLLEYBALL", lab: "Volleyball" },
  { val: "WATERPOLO", lab: "Wasserball" },
];


export { backend, sports };
