




























































import {backend} from '../modules/Global';
import logger from '../modules/Logger';
import axios from "axios";
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class App extends Vue {

    email = "";

    snackbar= {
      visible: false,
      text: "",
      timeout: 2000
    };

    send() {

      if(this.isEmpty(this.email)){
        return;
      }

      const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if(!this.email.match(mailformat))
      {
        alert("Mail-Adresse ungültig!");
        return;
      }

      let user = {
            "username" : this.email.toLowerCase()
        };
      

      axios.post(backend + "/forget", 
          user)
          .then(response => {
            this.$router.push("/login");
          })
          .catch(error => {
            this.snackbar.visible = true;
            this.snackbar.text = error.message;

            logger(error);
          });

    }

  }

