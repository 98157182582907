import Rollbar from "rollbar";

export default function logger(text) {
  /*
  rollbar = new Rollbar({
    accessToken: "5bdce700d5184f13b30e308d29279629",
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: "production",
    },
  });

  rollbar.error(text);
  */
 console.log(text);
}
