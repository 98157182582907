































































import { backend } from "../modules/Global";
import logger from "../modules/Logger";
import axios from "axios";
import Vue from "vue";
import moment from "moment";
import Component from "vue-class-component";

@Component
export default class App extends Vue {
  match = {};
  teamid = localStorage.getItem("team");

  snackbar = {
    visible: false,
    text: "",
    timeout: 2000,
  };

  mounted() {
    axios
      .get(backend + "/match/" + this.$route.params.id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        this.match = response.data;

        this.match.startDate = moment(this.match.startTime).format("DD.MM.YYYY");
        this.match.startClock = moment(this.match.startTime).format("kk:mm");
      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });
  }

  update() {

    let tmp = moment(this.match.startDate + " " + this.match.startClock, "DD.MM.YYYY kk:mm");

    if(tmp.isValid() === false){
      this.snackbar.visible = true;
      this.snackbar.text = "Ungültiges Datum oder Uhrzeit.";
      return;
    }
    
    this.match.startTime = tmp.format("YYYY-MM-DDTHH:mm:ss");

    axios
      .put(backend + "/match/" + this.teamid + "/" + this.match.id, this.match, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        this.$router.push("/matches");
      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });
  }

  remove() {
    this.$swal({ text: "Bist Du sicher?", confirmButtonText: "Ja", showDenyButton: true, denyButtonText: "Nein" }).then((r) => {
      if (r.value) {
        axios
          .delete(backend + "/match/" + this.match.id, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth"),
            },
          })
          .then((response) => {
            this.$router.push("/matches");
          })
          .catch((error) => {
            this.snackbar.visible = true;
            this.snackbar.text = error.message;

            logger(error);
          });
      }
    });
  }
}
