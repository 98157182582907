

































































































import { backend } from "../modules/Global";
import logger from "../modules/Logger";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import { Camera, CameraResultType } from '@capacitor/camera';

@Component
export default class App extends Vue {
  team = {};
  rating = {};
  photoChange = 0;
  reallyRemove = 0;

  snackbar = {
    visible: false,
    text: "",
    timeout: 2000,
  };


  save() {

    this.rating.teamid = this.team.id;
  
    axios
      .post(backend + "/rating", this.rating, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {

        this.$router.push("/invite/1");

      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });
      
  }

  

  mounted() {

    this.rating.fair = 1;
    this.rating.hospitality = 1;
    this.rating.strength = 1;

    axios.get(backend + "/ratings/" + this.$route.params.id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        
        for(let r of response.data){
          if(r.topic === 'strength'){
            this.rating.strength = r.rate;
          }
          if(r.topic === 'fair'){
            this.rating.fair = r.rate;
          }
          if(r.topic === 'hospitality'){
            this.rating.hospitality = r.rate;
          }
        }

      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });

    axios.get(backend + "/team/" + this.$route.params.id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        this.team = response.data;

      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });

      
  }
}
