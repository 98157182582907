










































import { backend } from "../modules/Global";
import logger from "../modules/Logger";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class App extends Vue {
  user = {};
  password = "";
  repeat = "";

  snackbar = {
    visible: false,
    text: "",
    timeout: 2000,
  };

  save() {
    let mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
    if (!mediumRegex.test(this.password)) {
      alert("Passwort nicht sicher");
      return;
    }

    if (this.password !== this.repeat) {
      alert("Passwörter sind verschieden");
      return;
    }

    let param = {
      username: this.user.username.toLowerCase(),
      password: this.password,
    };

    axios
      .put(backend + "/user/password", param, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        const auth = btoa(this.user.username.toLowerCase() + ":" + this.password);
        localStorage.setItem("auth", auth);
        this.$router.push("settings");
      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });
  }

  mounted() {
    axios
      .get(backend + "/user", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then((response) => {
        this.user = response.data;
      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;

        logger(error);
      });
  }
}
