import Vue from "vue";
import VueRouter from "vue-router";

import About from "../views/About.vue";
import MyTeams from "../views/MyTeams.vue";
import Invite from "../views/Invite.vue";
import Login from "../views/Login.vue";
import Forget from "../views/Forget.vue";
import Signup from "../views/Signup.vue";
import Signed from "../views/Signed.vue";
import Logout from "../views/Logout.vue";
import Settings from "../views/Settings.vue";
import Team from "../views/Team.vue";
import Member from "../views/Member.vue";
import MemberEdit from "../views/MemberEdit.vue";
import Matches from "../views/Matches.vue";
import Match from "../views/Match.vue";
import ChatList from "../views/ChatList.vue";
import ChatInvite from "../views/ChatInvite.vue";
import Chat from "../views/Chat.vue";
import Tournament from "../views/Tournament.vue";
import TournamentGuests from "../views/TournamentGuests.vue";
import TournamentGames from "../views/TournamentGames.vue";
import Rating from "../views/Rating.vue";
import Appointments from "../views/Appointments.vue";
import User from "../views/User.vue";
import UserPassword from "../views/UserPassword.vue";
import Overview from "../views/Overview.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Overview",
    component: Overview,
    meta: {
      requiresAuth: true,
      role: "ALL",
    },
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/forget",
    name: "Forget",
    component: Forget,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/signed",
    name: "Signed",
    component: Signed,
  },
  {
    path: "/my-teams",
    name: "MyTeams",
    component: MyTeams,
    meta: {
      requiresAuth: true,
      role: "ALL",
    },
  },
  {
    path: "/appointments",
    name: "Appointments",
    component: Appointments,
    meta: {
      requiresAuth: true,
      role: "ALL",
    },
  },
  {
    path: "/overview",
    name: "Overview",
    component: Overview,
    meta: {
      requiresAuth: true,
      role: "ALL",
    },
  },
  {
    path: "/invite/:opt",
    name: "Invite",
    component: Invite,
    meta: {
      requiresAuth: true,
      role: "LEADER",
    },
  },
  {
    path: "/matches",
    name: "Matches",
    component: Matches,
    meta: {
      requiresAuth: true,
      role: "LEADER",
    },
  },
  {
    path: "/match/:id",
    name: "Match",
    component: Match,
    meta: {
      requiresAuth: true,
      role: "LEADER",
    },
  },
  {
    path: "/user",
    name: "User",
    component: User,
    meta: {
      requiresAuth: true,
      role: "ALL",
    },
  },
  {
    path: "/user_password",
    name: "UserPassword",
    component: UserPassword,
    meta: {
      requiresAuth: true,
      role: "ALL",
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      requiresAuth: true,
      role: "ALL",
    },
  },
  {
    path: "/team",
    name: "Team",
    component: Team,
    meta: {
      requiresAuth: true,
      role: "LEADER",
    },
  },
  {
    path: "/member",
    name: "Member",
    component: Member,
    meta: {
      requiresAuth: true,
      role: "LEADER",
    },
  },
  {
    path: "/member/:id",
    name: "MemberEdit",
    component: MemberEdit,
    meta: {
      requiresAuth: true,
      role: "LEADER",
    },
  },
  {
    path: "/chat_list",
    name: "ChatList",
    component: ChatList,
    meta: {
      requiresAuth: true,
      role: "LEADER",
    },
  },
  {
    path: "/chat_invite",
    name: "ChatInvite",
    component: ChatInvite,
    meta: {
      requiresAuth: true,
      role: "LEADER",
    },
  },
  {
    path: "/tournament",
    name: "Tournament",
    component: Tournament,
    meta: {
      requiresAuth: true,
      role: "LEADER",
    },
  },
  {
    path: "/tournament_guests/:id",
    name: "TournamentGuests",
    component: TournamentGuests,
    meta: {
      requiresAuth: true,
      role: "LEADER",
    },
  },
  {
    path: "/tournament_games/:id",
    name: "TournamentGames",
    component: TournamentGames,
    meta: {
      requiresAuth: true,
      role: "LEADER",
    },
  },
  {
    path: "/chat/:id",
    name: "Chat",
    component: Chat,
    meta: {
      requiresAuth: true,
      role: "LEADER",
    },
  },
  {
    path: "/rating/:id",
    name: "Rating",
    component: Rating,
    meta: {
      requiresAuth: true,
      role: "LEADER",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("auth") === null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {

      if(!localStorage.getItem("auth").startsWith("eyJ")){
        next({
          path: "/login",
          params: { nextUrl: to.fullPath },
        });
      }

      if (to.path === "/my-teams") {
        next();
      }

      if (localStorage.getItem("team") === null) {
        next({
          path: "/my-teams",
          params: { nextUrl: to.fullPath },
        });
      } else {
        if (to.meta.role === "LEADER") {
          if (localStorage.getItem("role") === "LEADER") {
            next();
          } else {
            next({
              path: "/appointments",
              params: { nextUrl: to.fullPath },
            });
          }
        } else {
          next();
        }
      }
    }
  } else {
    next();
  }
});

export default router;
