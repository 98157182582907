





























































import { backend } from "../modules/Global";
import logger from "../modules/Logger";
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class App extends Vue {
  email = "";
  password = "";

  snackbar = {
    visible: false,
    text: "Ungültige Mail Adresse",
    timeout: 2000,
  };

  login() {
    this.email = this.email.trim();
    this.email = this.email.toLowerCase();

    if (this.isEmpty(this.email)) {
      this.snackbar.visible = true;
      this.snackbar.text = "Mail-Adresse fehlt!";
      return;
    }

    if (this.isEmpty(this.password)) {
      this.snackbar.visible = true;
      this.snackbar.text = "Passwort fehlt!";
      return;
    }

    const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!this.email.match(mailformat)) {
      this.snackbar.visible = true;
      this.snackbar.text = "Mail-Adresse ungültig!";
      return;
    }

    const auth = {
      "username": this.email.toLowerCase(),
      "password": this.password
    }; 

    axios
      .post(backend + "/authenticate", auth)
      .then((response) => {
        localStorage.setItem("auth", response.data);
        this.$router.push("/my-teams");
      })
      .catch((error) => {
        this.snackbar.visible = true;
        this.snackbar.text = error.message;
            
        logger(error);
      });
  }
}
